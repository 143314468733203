<template>
  <div class="form-item">
    <div class="form-label" :class="{ 'is-required': isRequired }">{{ formLabel }}</div>
    <div class="form-date">
      <DatePicker
        v-model="range"
        :masks="masks"
        :popover="popover"
        :model-config="modelConfig"
        :attributes="attrs"
        :columns="$screens({ lg: 2 }, 1)"
        :min-date="minDate"
        :max-date="maxDate"
        :disabled-dates="totalDisabledDates"
        @update:to-page="pageChange"
        is-range
      >
        <template v-slot="{ inputEvents: dateEvent }">
          <div class="form-date-wrapper">
            <span class="form-date-prefix">
              <i class="icon icon-calendar" aria-hidden="true"></i>
            </span>
            <Field :name="fieldStartName" v-model="range.start" v-if="useField" v-slot="{ value, errorMessage }">
              <input
                type="text"
                class="form-input form-date-startInput"
                :class="{ 'is-invalid': !!errorMessage }"
                :value="value"
                v-on="dateEvent.start"
                :name="fieldStartName"
                placeholder="開始日期"
                :disabled="!minDate && !maxDate"
              />
            </Field>
            <input
              v-else
              type="text"
              class="form-input form-date-startInput"
              :value="range.start"
              v-on="dateEvent.start"
              :name="fieldStartName"
              placeholder="開始日期"
            />

            <span class="form-date-to">-</span>
            <Field :name="fieldEndName" v-model="range.end" v-if="useField" v-slot="{ value, errorMessage }">
              <input
                type="text"
                class="form-input form-date-endInput"
                :class="{ 'is-invalid': !!errorMessage }"
                :value="value"
                v-on="dateEvent.end"
                :name="fieldStartName"
                placeholder="結束日期"
                :disabled="!minDate && !maxDate"
              />
            </Field>
            <input
              v-else
              type="text"
              class="form-input form-date-endInput"
              :value="range.end"
              v-on="dateEvent.end"
              :name="fieldStartName"
              placeholder="結束日期"
            />
          </div>
        </template>
      </DatePicker>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import api from '@/api/api';
import { DatePicker } from 'v-calendar';
import { Field } from 'vee-validate';

export default {
  components: { DatePicker, Field },
  props: {
    useField: {
      type: Boolean,
      default: true,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    formLabel: {
      type: String,
      default: '日期區間',
    },
    fieldStartName: String,
    fieldEndName: String,
    minDate: {
      type: String,
      default: null,
    },
    maxDate: {
      type: String,
      default: null,
    },
    disabledDates: {
      type: Array,
      default: () => [],
    },
    fetchEventBySiteId: {
      type: Number,
      default: 0,
    },
    fetchArea: {
      type: String,
      default: '',
    },
    fetchSessionType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      range: {
        start: null,
        end: null,
      },
      popover: {
        keepVisibleOnInput: true,
        visibility: 'focus',
      },
      masks: {
        title: 'YYYY MMMM',
        input: 'YYYY-MM-DD',
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      systemEvents: [],
      systemDisabledDates: [],
    };
  },
  methods: {
    async pageChange({ month, year }) {
      let startDate = null;
      let endDate = null;

      if (month === 1) {
        // 跨年度顯示 bug
        startDate = this.$moment({ year: year - 1, month: 11 });
        endDate = this.$moment({ year, month: 0 });
      } else {
        startDate = this.$moment({ year, month: month - 2 });
        endDate = this.$moment({ year, month: month - 1 });
      }
      this.$emit('calendarChanged', startDate, endDate);

      // 需要撈取場館 systemEvents
      if (this.fetchEventBySiteId !== 0) {
        this.fetchSystemEvents({
          startDate: startDate.startOf('month').format('YYYY-MM-DD'),
          endDate: endDate.endOf('month').format('YYYY-MM-DD'),
        });
      }
    },
    async fetchSystemEvents(config) {
      const events = await api.getDatepickerEvents({
        startDate: config.startDate,
        endDate: config.endDate,
        siteId: this.fetchEventBySiteId,
        rentRange: this.fetchArea,
        activitySessionType: this.fetchSessionType,
      });
      this.systemEvents = _.chain(events)
        .filter(['isOpen', false])
        .groupBy('title')
        .mapValues((dates) => _.map(dates, 'startDate'))
        .omit('')
        // .tap((x) => console.log(x))
        .value();
      this.systemDisabledDates = _.chain(events)
        .filter(['isDuringApplication', false])
        .map('startDate')
        .value();
    },
  },
  computed: {
    attrs() {
      return [
        ...this.dotDates,
      ];
    },
    dotDates() {
      return _.map(this.systemEvents, (val, key) => ({
        dot: 'orange',
        dates: val,
        order: 1,
        popover: {
          label: key,
        },
      }));
    },
    totalDisabledDates() {
      return [...this.disabledDates, ...this.systemDisabledDates];
    },
  },
  watch: {
    range: {
      deep: true,
      handler(newRange, oldRange) {
        const { start, end } = newRange;
        const duration = this.dateDuration(start, end) || null;
        this.$emit('rangeChange', { start, end, duration }, oldRange);
      },
    },
  },
};
</script>
