<template>
  <div>
    <div class="project-filter">
      <div class="grid-row-noBottom">
        <div class="col-12_md-2">
          <div class="form-item">
            <div class="form-label">場館</div>
            <div class="form-select">
              <select v-model.number="filter.site">
                <option value="0">全部</option>
                <option value="1">表演廳</option>
                <option value="2">文化館</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12_md-2">
          <div class="form-item">
            <div class="form-label">審核狀態</div>
            <div class="form-select">
              <select v-model.number="filter.status">
                <option value="0">全部</option>
                <option value="1">已通過</option>
                <option value="2">審核中</option>
                <option value="3">不通過</option>
                <option value="4">草稿</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12_md-5">
          <DateRange :is-required="false" :use-field="false" @range-change="setFilterDateRange" />
        </div>
        <div class="col-12_md-3">
          <div class="form-item">
            <div class="form-label">關鍵字</div>
            <div class="form-group">
              <input type="text" class="form-input" v-model="filter.keyword" />
              <button class="btn btn-secondary form-input-btn" v-if="hasFilter" @click="clearFilter()" title="清除條件">
                <i class="icon icon-close"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="project-filter">
      <div class="grid-row-noBottom">
        <div class="col-6_md-9">
          共 <span class="text-danger">{{ filtedUserEvents.length }}</span> 筆
          <button class="btn btn-light d-none-tablet ml-2" @click="sheetExport()">
            <i class="icon icon-download"></i> 匯出 Excel
          </button>
        </div>
        <div class="col-6_md-3">
          <div class="form-select">
            <select v-model="sortBy">
              <option value="desc">申請日期(新→舊)</option>
              <option value="asc">申請日期(舊→新)</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <table class="table projectList" :class="{ 'is-loading': isLoading }">
      <thead>
        <tr>
          <th class="d-none-md-below" width="10%">編號</th>
          <th width="33%">名稱</th>
          <th class="d-none-xl-below">申請日期</th>
          <th class="d-none-sm-below">狀態</th>
          <th class="d-none-sm-below" width="20%">操作</th>
          <th class="table-collapse-column" width="1%"></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="!isLoading">
          <template v-if="userEvents.length !== 0">
            <template v-for="event in sortingUserEvents" :key="event.numNo">
              <tr
                :id="event.numNo"
                :class="{ 'is-highlight': !!event.numNo && event.numNo === highlightId }"
              >
                <td class="d-none-md-below text-center">{{ event.numNo || '-' }}</td>
                <td>
                  <RouterLink
                    :to="`/user/project/${event.siteId === 1 ? 'concert' : 'cultural'}/${event.id}`"
                    >{{ event.title }}</RouterLink
                  >
                  <div>
                    <small class="tag" :class="event.siteId === 1 ? 'tag-blue' : 'tag-orange'">
                      {{ event.siteId === 1 ? "表演廳" : "文化館" }}
                    </small>
                  </div>
                </td>
                <td class="d-none-xl-below text-center">
                  {{ event.createTime ? $moment(event.createTime).format("L") : '-' }}
                </td>
                <td class="d-none-sm-below">
                  <!-- 狀態 -->
                  <StatusBadge
                    :status="event.resultStatus"
                    :first-status="event.firstStatus"
                    :second-status="event.secondStatus"
                  />
                </td>
                <td class="d-none-sm-below">
                  <!-- 操作 -->
                  <div v-if="event.resultStatus === 1">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="showChargeModal(event.siteId, event.id)"
                    >
                      繳費資訊
                    </button>
                    <div>
                      <small v-if="event.notification.length > 0" class="text-danger">
                        <i class="icon icon-bell"></i>
                        請於{{ $moment(event.notification[0].expectedDate).format("L") }}前繳清
                        {{ event.notification[0].chargeName }}
                      </small>
                    </div>
                  </div>
                  <div v-if="event.resultStatus === 4" style="display: flex; white-space: nowrap">
                    <button
                      type="button"
                      @click="loadDraft(event.siteId, event.id)"
                      class="btn btn-primary"
                    >
                      繼續填寫
                    </button>
                    <Popper arrow>
                      <button type="button" class="btn btn-light ml-1">
                        <i class="icon icon-trash"></i>
                      </button>
                      <template #content="{ close }">
                        <div>
                          確定刪除?
                          <div>
                            <button type="button" class="btn btn-secondary btn-sm" @click="close">取消</button>
                            <button
                              type="button"
                              class="btn btn-orange btn-sm"
                              @click="deleteEvent(event.siteId, event.id)"
                            >
                              確定
                            </button>
                          </div>
                        </div>
                      </template>
                    </Popper>
                  </div>
                </td>
                <td class="table-collapse-column">
                  <button
                    type="button"
                    class="btn btn-dark collapse-btn"
                    @click="toggleCollapse(`${event.siteId}${event.id}`)"
                  >
                    <i
                      class="icon"
                      :class="
                        collapseStatus[`${event.siteId}${event.id}`] ? 'icon-minus' : 'icon-plus'
                      "
                      aria-hidden="true"
                    ></i>
                  </button>
                </td>
              </tr>
              <tr
                class="table-collapse-content"
                v-show="collapseStatus[`${event.siteId}${event.id}`]"
              >
                <td colspan="7">
                  <dl>
                    <dt>編號</dt>
                    <dd>{{ event.numNo || '-' }}</dd>
                    <dt>申請日期</dt>
                    <dd>{{ event.createTime ? $moment(event.createTime).format("L") : '-' }}</dd>
                    <dt>狀態</dt>
                    <dd>
                      <StatusBadge
                        :status="event.resultStatus"
                        :first-status="event.firstStatus"
                        :second-status="event.secondStatus"
                      />
                    </dd>
                  </dl>
                  <div v-if="event.resultStatus === 1">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="showChargeModal(event.siteId, event.id)"
                    >
                      繳費資訊
                    </button>
                    <div>
                      <small v-if="event.notification.length > 0" class="text-danger">
                        <i class="icon icon-bell"></i>
                        請於{{ $moment(event.notification[0].expectedDate).format("L") }}前繳清{{
                          event.notification[0].chargeName
                        }}
                      </small>
                    </div>
                  </div>
                  <div v-if="event.resultStatus === 4">
                    <button
                      type="button"
                      @click="loadDraft(event.siteId, event.id)"
                      class="btn btn-primary"
                    >
                      繼續填寫
                    </button>
                    <Popper arrow>
                      <button type="button" class="btn btn-light ml-1">
                        <i class="icon icon-trash"></i>
                      </button>
                      <template #content>
                        <div>
                          確定刪除?
                          <div>
                            <button type="button" class="btn btn-secondary btn-sm">取消</button>
                            <button type="button" class="btn btn-orange btn-sm">確定</button>
                          </div>
                        </div>
                      </template>
                    </Popper>
                  </div>
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr>
              <td colspan="6" class="text-center">您尚無申請紀錄</td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
    <small>最後更新時間：{{ $moment(lastUpdatedTime).format("YYYY-MM-DD kk:mm") }}</small>

    <div class="panel mt-3">
      <div class="panel-block">
        <div class="panel-block-title">場租費用說明</div>
        <p>有關各場地租借費用、技術資料、場地說明，請點選以下相關連結</p>
        <div class="btn-group">
          <a class="btn btn-light btn-md" href="https://tmc.taipei/hire/hire-hall/" target="_blank">
            表演廳更多說明 <i class="icon icon-external"></i>
          </a>
          <a
            class="btn btn-light btn-md"
            href="https://tmc.taipei/hire/hire-culture/"
            target="_blank"
          >
            文化館廳更多說明 <i class="icon icon-external"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="btn-group d-none-pc">
      <RouterLink class="btn btn-primary btn-md" to="/rent/concert">表演廳檔期申請</RouterLink>
      <RouterLink class="btn btn-primary btn-md" to="/rent/concert">文化館檔期申請</RouterLink>
    </div>

    <Modal :is-show="chargeModal.isShow" size="lg" @close="closeChargeModal">
      <template #header>
        <div>繳費資訊</div>
      </template>
      <template #body>
        <div class="swipeTable-wrapper">
          <table class="table">
            <tbody>
              <tr>
                <th class="fixed-th"></th>
                <td>金額</td>
                <td>繳費狀態</td>
              </tr>
              <tr v-for="row in chargeModal.table" :key="row.chargeName">
                <th class="fixed-th">{{ row.chargeName }}</th>
                <td>NT${{ thousandsFormatter(row.expectedAmount) }}</td>
                <td>
                  <span v-if="row.chargeStatus === 1" class="text-danger">
                    <template v-if="row.expectedDate"
                      >請於 {{ $moment(row.expectedDate).format("L") }} 前繳納</template
                    >
                    <template v-else>未繳納</template>
                  </span>
                  <span v-if="row.chargeStatus === 2">已繳納</span>
                  <span v-if="row.chargeStatus === 3">已逾期</span>
                  <span v-if="row.chargeStatus === 4">-</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ul class="labelList">
          <li class="labelList-item">
            <div class="labelList-item-label">繳款方式：</div>
            <div class="labelList-item-content">
              請用<strong>轉帳</strong>或<strong>即期支票</strong>繳費
            </div>
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>
<script>
import _ from 'lodash';
import Modal from '@/components/Modal.vue';
import StatusBadge from '@/components/StatusBadge.vue';
import DateRange from '@/components/Input/DateRange.vue';
import { mapActions, mapState } from 'pinia';
import { useViewportWidth } from '@/store/index';
import { useUserEvents } from '@/store/user';
import { useFormStore } from '@/store/form';
import { projectsExport } from '@/utils/fileExport';

export default {
  components: {
    Modal,
    StatusBadge,
    DateRange,
  },
  data() {
    return {
      filter: {
        site: 0,
        status: 0,
        startDate: null,
        endDate: null,
        keyword: '',
      },
      sortBy: 'desc',
      collapseStatus: {},
      chargeModal: {
        isShow: false,
        table: [],
      },
    };
  },
  methods: {
    setFilterDateRange({ start, end }) {
      this.filter.startDate = start;
      this.filter.endDate = this.$moment(end).endOf('day');
    },
    toggleCollapse(id) {
      this.collapseStatus[id] = !this.collapseStatus[id];
    },
    clearFilter() {
      this.filter = {
        site: 0,
        status: 0,
        startDate: null,
        endDate: null,
        keyword: '',
      };
    },
    async showChargeModal(siteId, id) {
      const table = await this.fetchUserEventNotification({ siteId, id });
      this.chargeModal.table = this.chargeFormatter(table);
      this.chargeModal.isShow = true;
    },
    closeChargeModal() {
      this.chargeModal.isShow = false;
      this.chargeModal.table = [];
    },
    async deleteEvent(siteId, id) {
      await this.deleteUserEvent(siteId, id);
      this.fetchUserEvents();
    },
    sheetExport() {
      projectsExport(this.filtedUserEvents, `projects_${this.$moment().format('YYYYMMDDHHmm')}`);
    },
    ...mapActions(useUserEvents, [
      'fetchUserEvents',
      'fetchUserEventNotification',
      'deleteUserEvent',
    ]),
    ...mapActions(useFormStore, ['loadDraft']),
  },
  computed: {
    highlightId() {
      return this.$route.query.id || null;
    },
    hasFilter() {
      return !_.isEqual(this.filter, {
        site: 0,
        status: 0,
        startDate: null,
        endDate: null,
        keyword: '',
      });
    },
    filtedUserEvents() {
      const {
        site, status, startDate, endDate, keyword,
      } = this.filter;

      // 生成篩選 [NEED REFACTOR: (!!!依條件生成)通用 pipe function]
      const filterPipe = (() => {
        const pipe = [];
        // filter 工廠
        const filterCreator = (filterFunc) => (items) => _.filter(items, filterFunc);
        // 依條件生成 filter 推入 pipe
        if (site !== 0) pipe.push(filterCreator(({ siteId }) => siteId === site));
        if (status !== 0) pipe.push(filterCreator(({ resultStatus }) => resultStatus === status));
        if (startDate && endDate) {
          pipe.push(
            filterCreator(({ createTime }) => this.$moment(createTime).isBetween(startDate, endDate)),
          );
        }
        if (keyword.trim() !== '') pipe.push(filterCreator(({ title, numNo }) => [title, numNo].includes(keyword.trim())));
        // 沒有任何條件
        if (pipe.length === 0) pipe.push(filterCreator(() => true));
        // 生成 pipe function
        return _.flow(pipe);
      })();

      // 返回篩選後的 events
      return filterPipe(this.userEvents);
    },
    sortingUserEvents() {
      return _.orderBy(this.filtedUserEvents, 'createTime', this.sortBy);
    },
    ...mapState(useViewportWidth, ['viewportWidth']),
    ...mapState(useUserEvents, ['isLoading', 'userEvents', 'lastUpdatedTime']),
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(newRoute) {
        if (newRoute.query.id) {
          this.$nextTick(() => {
            document.querySelector(`#${newRoute.query.id}`).scrollIntoView({ block: 'center' });
          });
        }
      },
    },
  },
  created() {
    // 收合狀態
    this.collapseStatus = _.reduce(
      this.userEvents,
      (result, event) => ({ ...result, [`${event.siteId}${event.id}`]: false }),
      {},
    );
  },
};
</script>
