import _ from 'lodash';
import { defineStore } from 'pinia';
import api from '@/api/api';
import { useLoadingScreen } from './index';
import { useFormStore } from './form';

export const useUserData = defineStore('userData', {
  state: () => ({
    token: localStorage.getItem('token') || null,
    userId: null,
    userData: {
      unitType: 1,
      unitName: '',
      unNumber: '',
      headerName: '',
      name: '',
      iDNumber: '',
      contactName: '',
      contactEmail: '',
      contactTel: '',
      contactMobile: '',
      contactFax: '',
      address: '',
      otherName: '',
      otherEmail: '',
      otherTel: '',
      otherMobile: '',
    },
  }),
  getters: {
    isLogin({ token }) {
      return token !== null;
    },
    isCompanyAccount({ userData }) {
      return userData.unitType === 1;
    },
    userName() {
      return this.isCompanyAccount ? this.userData.unitName : this.userData.name;
    },
    userDataForForm() {
      const excludeField = [
        'unitType',
        'unitName',
        'unNumber',
        'headerName',
        'name',
        'iDNumber',
        'contactFax',
      ];
      return _.omit(this.userData, excludeField);
    },
    userDataForProfileForm() {
      const excludeField = [
        'unitName',
        'unNumber',
        'headerName',
        'name',
        'iDNumber',
      ];
      return _.omit(this.userData, excludeField);
    },
  },
  actions: {
    async login(loginData) {
      const loading = useLoadingScreen();
      const formStore = useFormStore();

      loading.setLoading(true);
      const { token } = await api.login(loginData);
      if (!token) {
        alert('帳號或密碼錯誤');
        loading.setLoading(false);
        return false;
      }
      this.token = token;
      localStorage.setItem('token', this.token);

      await this.fetchUserData();
      // 導向 project 表
      this.router.push('/user/project');
      await formStore.initForm();
      loading.setLoading(false);
      return true;
    },
    async logout() {
      const loading = useLoadingScreen();
      loading.setLoading(true);
      // 清除 localStorage token
      localStorage.removeItem('token');
      this.token = null;
      localStorage.removeItem('token');
      this.router.push('/login');
      loading.setLoading(false);
    },
    async fetchUserData() {
      let data = await api.getUserData();
      data = _.mapValues(data, (val) => (val === null ? '' : val));
      this.userData = _.omit(data, ['id']);
      this.userId = data.id;
    },
    async updateUserData(formData) {
      const loading = useLoadingScreen();

      loading.setLoading(true);
      const submitData = _.merge({}, this.userData, formData);
      await api.updateUserData(submitData);
      await this.fetchUserData();
      loading.setLoading(false);
    },
    async updateUserPassword(formData) {
      const loading = useLoadingScreen();

      loading.setLoading(true);
      const isPassed = await api.updateUserPassword(formData);
      if (!isPassed) alert('舊密碼錯誤');
      loading.setLoading(false);
      return isPassed;
    },
  },
});

export const useUserEvents = defineStore('userEvents', {
  state: () => ({
    isLoading: false,
    userEvents: [],
    userEventsPassedOnly: [],
    lastUpdatedTime: new Date().getTime(),
  }),
  actions: {
    async fetchUserEvents() {
      this.isLoading = true;
      this.lastUpdatedTime = new Date().getTime();
      this.userEvents = await api.getUserEvents();
      this.userEventsPassedOnly = await api.getUserEvents(true);
      this.isLoading = false;
    },
    fetchUserEvent(siteId, id) {
      const method = siteId === 1 ? 'userConcertEvent' : 'userCulturalEvent';
      return api[method].get(id);
    },
    fetchUserEventNotification(config) {
      return api.getUserEventNotification(config);
    },
    deleteUserEvent(siteId, id) {
      const method = siteId === 1 ? 'userConcertEvent' : 'userCulturalEvent';
      return api[method].delete(id);
    },
  },
});

export const useUserNotification = defineStore('userNotification', {
  state: () => ({
    notifications: [],
  }),
  actions: {
    async fetchUserNotification() {
      const loading = useLoadingScreen();
      loading.setLoading(true);
      this.notifications = await api.getUserNotifications();
      loading.setLoading(false);
    },
  },
});
