import _ from 'lodash';
import moment from 'moment';

const dateDuration = (startDate, endDate) => moment.duration((moment(endDate)).diff(moment(startDate))).asDays() + 1;
const thousandsFormatter = (number) => (_.isNumber(number) ? number.toLocaleString('zh-Hant') : null);

const arrayToObjectByKey = (array, keyName) => _.reduce(array, (result, item) => {
  result[item[keyName]] = _.omit(item, keyName);
  return result;
}, {});

const culturalGuaranteeName = _.cond([
  [(num) => num < 30, () => 'under30'],
  [(num) => num >= 30 && num < 90, () => 'under90'],
  [(num) => num >= 90, () => 'above90'],
]);

export const culturalFormula = (data) => {
  if (_.some(_.values(data), _.isEmpty)) {
    // console.log(_.isEmpty(data.feeTable));
    return {
      entryExitsFee: '-',
      showDayFee: '-',
      guaranteeFee: '-',
      totalFee: '-',
    };
  }
  // 起訖日，演出起訖日
  const {
    totalStartDate,
    totalEndDate,
    showStartDate,
    showEndDate,
    feeTable,
  } = data;

  const totalDayCount = dateDuration(totalStartDate, totalEndDate);
  const showDayCount = dateDuration(showStartDate, showEndDate);
  // 總日 - 演出日 = 進撤場日/天
  // console.log(`進撤場日費: ${feeTable.entryExit} * (${totalDayCount} - ${showDayCount})`);
  const entryExitsFee = feeTable.entryExit * (totalDayCount - showDayCount);
  // 演出日/天
  // console.log(`演出日費: ${feeTable.showDay} * ${showDayCount}`);
  const showDayFee = feeTable.showDay * showDayCount;
  // 保證金
  // console.log(`保證金: ${feeTable[culturalGuaranteeName(totalDayCount)]}`);
  const guaranteeFee = feeTable[culturalGuaranteeName(totalDayCount)];
  // 總計(不含保證金)
  const totalFee = entryExitsFee + showDayFee;

  return _.mapValues({
    entryExitsFee,
    showDayFee,
    guaranteeFee,
    totalFee,
  }, (num) => thousandsFormatter(num));
};

export const concertFormula = (data) => {
  const cloneData = _.cloneDeep(data);
  // 進撤場日非必填
  if (_.some(_.values(_.omit(cloneData, ['entryList', 'exitsList'])), _.isEmpty)) {
    return {
      entryExitsFee: '-',
      showDayFee: '-',
      guaranteeFee: '-',
      totalFee: '-',
    };
  }

  if (cloneData.entryExitsList) {
    // 把 entryExitsList 拆分成 entryList + exitsList
    const whichList = ({ entryExitDate }) => (moment(entryExitDate).isBefore(cloneData.showStartDate) ? 'entryList' : 'exitsList');
    const groupedObj = _.groupBy(cloneData.entryExitsList, whichList);
    _.merge(cloneData, groupedObj);
  }

  const {
    totalStartDate,
    totalEndDate,
    showStartDate,
    showEndDate,
    sessionList,
    entryList,
    exitsList,
    activityCharge,
    feeTable,
  } = cloneData;

  console.log(cloneData);

  // 各類日程的收費標準
  // 1:進場日 2:撤場日 3:演出日(售票) 4:演出日(索票)
  const formatedFeeTable = arrayToObjectByKey(feeTable, 'chargeType');

  const table = {
    entry: {
      totalDayCount: dateDuration(totalStartDate, showStartDate),
      list: entryList,
      currentFeeTable: formatedFeeTable['1'],
    },
    show: {
      totalDayCount: dateDuration(showStartDate, showEndDate),
      list: sessionList,
      currentFeeTable: activityCharge === '1' ? formatedFeeTable['3'] : formatedFeeTable['4'],
    },
    exit: {
      totalDayCount: dateDuration(showEndDate, totalEndDate),
      list: exitsList,
      currentFeeTable: formatedFeeTable['2'],
    },
  };

  const result = _.mapValues(table, ({ totalDayCount, list, currentFeeTable }, tableKey) => {
    // [實日, 空日]
    const feePerDay = [
      _.chain(currentFeeTable)
        .pick(['service', 'elcAir'])
        .values()
        .sum(),
      currentFeeTable.occupy,
    ];

    // 實日費用
    const actualDaySum = (() => {
      const { periodFeeList } = currentFeeTable;
      const basicFee = list.length * feePerDay[0];
      let periodFee = 0;

      if (tableKey === 'show') {
        // 演出日金額固定
        periodFee = list.length * periodFeeList[0].periodFee;
      } else {
        // 進撤場日依使用者選的時段加總
        periodFee = _.chain(list)
          // .tap((x) => { console.log(x); })
          .map('timeDuration')
          .map((timeDurations) => _.map(timeDurations, (timeId) => {
            const targetPeriod = periodFeeList.find((period) => period.periodId === timeId);
            if (!targetPeriod) console.log(`not found period: ${timeId}`);
            return targetPeriod?.periodFee || 0;
          }))
          .map((dayFee) => _.sum(dayFee))
          .sum()
          .value();
      }
      return basicFee + periodFee;
    })();
    // 空日費用
    // console.log(`${feePerDay[1]} * (${totalDayCount} - ${list.length})`);
    const emptyDaySum = feePerDay[1] * (totalDayCount - list.length);

    return {
      total: actualDaySum + emptyDaySum,
      guaranteeFee: totalDayCount * currentFeeTable.deposit,
    };
  });

  console.log(result);

  return _.mapValues({
    entryExitsFee: result.entry.total + result.exit.total,
    showDayFee: result.show.total,
    guaranteeFee: result.entry.guaranteeFee + result.show.guaranteeFee + result.exit.guaranteeFee,
    totalFee: result.entry.total + result.show.total + result.exit.total,
  }, (num) => thousandsFormatter(num));
};
