import { utils, writeFileXLSX } from 'xlsx';
import _ from 'lodash';

// 案件列表輸出
const keyNameTable = {
  numNo: '編號',
  siteId: '場館',
  createTime: '申請時間',
  title: '活動名稱',
  resultStatus: '審核狀態',
  firstStatus: '第一志願',
  secondStatus: '第二志願',
};

const statusCodeTable = {
  1: '已審核',
  2: '審核中',
  3: '不通過',
  4: '草稿',
};
const subStatusCodeTable = {
  1: '正取',
  2: '備取1',
  3: '備取2',
  4: '無資格',
};

const keyValueHandler = {
  siteId: (siteId) => (siteId === 1 ? '表演廳' : '文化館'),
  createTime: (time) => new Date(time).toLocaleDateString('zh-TW'),
  resultStatus: (statusCode) => statusCodeTable[statusCode],
  firstStatus: (statusCode) => subStatusCodeTable[statusCode],
  secondStatus: (statusCode) => subStatusCodeTable[statusCode],
};

function sheetFormatter(data) {
  function formatter(dataItem) {
    return _.chain(dataItem)
      .pick(_.keys(keyNameTable))
      .mapValues((val, key) => (keyValueHandler[key] ? keyValueHandler[key](val) : val))
      .mapKeys((val, key) => keyNameTable[key])
      .value();
  }

  return _.map(data, formatter);
}

export function projectsExport(data, fileName) {
  const ws = utils.json_to_sheet(sheetFormatter(data));
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, 'Data');
  writeFileXLSX(wb, `${fileName}.xlsx`);
}
