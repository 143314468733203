<template>
  <!-- {{ status }}{{ firstStatus }}{{ secondStatus }}{{ isShowStatus }} -->
  <div class="status" :class="`status-${statusClass(status)}`" v-if="isShowStatus">
    <span v-if="status === 1">已通過</span>
    <span v-if="status === 2">審核中</span>
    <span v-if="status === 3">不通過</span>
    <span v-if="status === 4">草稿</span>
  </div>
  <div v-else>
    <div class="mb-1 status" :class="`status-${subStatusClass(firstStatus)}`">
      <span v-if="firstStatus === 1">意願1: 正取</span>
      <span v-if="firstStatus === 2">意願1: 備取1</span>
      <span v-if="firstStatus === 3">意願1: 備取2</span>
      <span v-if="firstStatus === 4">意願1: 無資格</span>
    </div>
    <div class="status" :class="`status-${subStatusClass(secondStatus)}`" v-if="secondStatus !== 0">
      <span v-if="secondStatus === 1">意願2: 正取</span>
      <span v-if="secondStatus === 2">意願2: 備取1</span>
      <span v-if="secondStatus === 3">意願2: 備取2</span>
      <span v-if="secondStatus === 4">意願2: 無資格</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    firstStatus: {
      type: Number,
      default: null,
    },
    secondStatus: {
      type: Number,
      default: null,
    },
    status: {
      type: Number,
      default: null,
    },
  },
  methods: {
    statusClass(statusCode) {
      if (statusCode === 1) return 'primary';
      if (statusCode === 2) return 'hold';
      if (statusCode === 3) return 'cancel';
      if (statusCode === 4) return 'draft';
      return '';
    },
    subStatusClass(statusCode) {
      if (statusCode === 1) return 'primary';
      if (statusCode === 2 || statusCode === 3) return 'reverse';
      if (statusCode === 4) return 'cancel';
      return '';
    },
  },
  computed: {
    isShowStatus() {
      // true:
      // 案件狀態不是 1
      // 案件狀態是 1 且志願狀態都是 0
      if (this.status !== 1) return true;
      if (this.firstStatus === 0 && this.secondStatus === 0) return true;
      return false;
    },
  },
};
</script>
